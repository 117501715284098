import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { ExamTrainingService } from '@app/_services/exam-training.service';
import {QuizDataClientModel, QuizListModel} from '@app/_models/quiz-data-client.model';
import { QuizAnswerModel } from '@app/_models/quiz-answer.model';
import { QuizResultModel } from '@app/_models/quiz-result.model';
import { fadeAnimationIn, inOutAnimation } from '@app/_animations/animations';
import { QuizCategoryModel } from '@app/_models/quiz-category.model';
import { GenericService } from '@app/_services/generic.service';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-exam-training',
  templateUrl: './exam-training.component.html',
  styleUrls: ['./exam-training.component.scss'],
  animations: [
    fadeAnimationIn,
    inOutAnimation
  ]
})
export class ExamTrainingComponent implements OnInit {

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChildren('cd') private countdownElements: QueryList<ElementRef>;

  apiUrl = environment.apiUrl;

  quizNumber= 0;
  questionsAnswered = 0;
  currentQuestion: QuizDataClientModel = new QuizDataClientModel();

  topics: QuizCategoryModel[] = [];
  quizIdList: QuizListModel[] = [];
  totalQuestions = 0;
  actualQuestionIndex = 0;
  quizDataList: QuizDataClientModel[] = [];

  correctAnswers = 0;
  wrongAnswers = 0;

  quizStatistics = {};

  showExplanation = false;
  showLastQuestionExplanation = false;
  isQuizFinished = false;
  quizFinished = false;
  showMenu = true;
  disableQuizAnswer = true;
  disableTopicSelection = false;

  // This variable to set duration of each question
  questionTime = 10;

  timerConfig = {
    leftTime: 30,
    format: 'mm:ss'
  };

  currentTopic = 0;
  sessionToken = '';

  pieOptions = {
    legend: {
      labels: {
        fontColor: '#eee8e8',
      }
    }
  };

  resultBgColor = null;

  resultTitle = '';
  resultText = '';

  trialMode:boolean = false;
  authenticationServiceSub: Subscription;

  constructor(
    private examTrainingService: ExamTrainingService,
    private genericService: GenericService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.examTrainingService.getTopicList().subscribe(res => {
      this.topics = res;
      //console.log(res);
    });

    this.authenticationServiceSub = this.authenticationService.user.subscribe(u => {
      if (u.role== "Trial" ){
        this.trialMode = true;
      }
      else {
        this.trialMode = false;
      }
    })
  }

  ngOnDestroy() {
    if(this.authenticationServiceSub){
        this.authenticationServiceSub.unsubscribe();
    }
  }

  topicSelected(topic) {

    if(topic.id> 1 && this.trialMode)
      return;

    this.disableTopicSelection = true;
    this.sessionToken = this.genericService.generateSessionToken();
    this.examTrainingService.getQuizListByTopicId(topic.id).subscribe(quizIdList => {
      this.currentTopic = topic.id;
      //console.log('Lesson topic quiz list', quizIdList);
      this.quizIdList = quizIdList;
      this.totalQuestions = quizIdList.length;

      // Set timer lenght based on question number
      this.timerConfig = {
        leftTime: this.totalQuestions * this.questionTime,
        format: 'mm:ss'
      };

      this.getQuizData();
    });
  }

  getQuizData() {    
    this.examTrainingService.getQuizDataByQuizId(this.quizIdList[this.actualQuestionIndex].quizId).subscribe(r => {
      this.currentQuestion = r;
      this.quizDataList.push(r);
      this.showMenu = false;
      this.disableQuizAnswer = false;
      this.quizNumber ++;
    });
  }

  answer(result: boolean) {

    this.questionsAnswered++;
    this.currentQuestion.answer = result;

    // Check to prevent multiple answer of the same question
    if (!this.currentQuestion.answered) {
      this.disableQuizAnswer = true;
      const quizAnswer = new QuizAnswerModel();
      quizAnswer.answer = result;
      quizAnswer.quizId = this.currentQuestion.quizData.id;
      quizAnswer.sessionToken = this.sessionToken;
      this.examTrainingService.postQuizResponse(quizAnswer).subscribe(r => {
        this.currentQuestion.quizResult = r;
        //this.currentQuestion.quizData.questionText = '';
        //this.currentQuestion.quizData.image = null;
        //console.log('response for the answer', r);
        // Here i've to check the response
        this.handleResponseCheck(r);
        if (this.calculateQuizCompletion() === 100) {
          this.isQuizFinished = true;
        }
      });
    }
  }

  handleTimerEvent($event) {
    //console.log($event);
    this.timerConfig.leftTime = $event.left / 1000;
    if (this.timerConfig.leftTime === 0) {
      this.handleAnswersLeft();
    }
  }

  // Set all the question left as wrong
  handleAnswersLeft() {
    // If less answer was done
    if (this.questionsAnswered !== this.quizIdList.length) {
      this.currentQuestion = null;
      this.wrongAnswers = this.wrongAnswers + (this.quizIdList.length - this.questionsAnswered);
      this.isQuizFinished = true;
      this.createQuizStatistics();
    }
  }

  handleResponseCheck(answer: QuizResultModel) {
    this.currentQuestion.answered = true;
    // If the answer is wrong, show video and pause countdown
    if (!answer.result) {
      //console.log('Wrong answer.');
      this.wrongAnswers ++;
      try {
        this.countdown.pause();
      } catch (e) {}
      this.showExplanation = true;
      this.currentQuestion.isCorrectAnswer = false;

      // If I answer in the wrong way to the last question
      if (this.actualQuestionIndex === this.totalQuestions) {
        this.showLastQuestionExplanation = true;
      }
    } else {
      //console.log('Right answer.');
      this.correctAnswers ++;
      this.currentQuestion.isCorrectAnswer = true;
      this.goToNextQuestion();
    }
  }

  goToNextQuestion() {
    if (this.actualQuestionIndex !== this.totalQuestions - 1) {
      this.nextQuestion();
    } else {
      this.createQuizStatistics();
    }
  }

  backToQuiz() {
    this.showExplanation = false;
    this.showLastQuestionExplanation = false;
    this.currentQuestion.quizResult.explanationText = '';
    this.goToNextQuestion();
  }

  nextQuestion() {
    this.actualQuestionIndex++;

    // Getting the next quizData, if not already present in the quizDataList
    if (!this.quizDataList[this.actualQuestionIndex]) {
      this.getQuizData();
    } else {
      this.currentQuestion = this.quizDataList[this.actualQuestionIndex];
      this.disableQuizAnswer = false;
    }
  }

  calculateQuizCompletion(): number {
    const quizCompletion = Math.round((this.questionsAnswered / this.totalQuestions) * 100);
    if (quizCompletion === 100) {
      try {
        this.countdown.pause();
      } catch (e) {}
    }
    return quizCompletion;
  }

  createQuizStatistics() {
    this.quizStatistics = {
      labels: ['Risposte corrette', 'Risposte errate'],
      datasets: [{
        data: [this.correctAnswers, this.wrongAnswers],
        backgroundColor: ['#66BB6A', '#FF0000']
      }]
    };

    const currentCorrectPercentage = this.calculateCorrectPercentage();

    if (currentCorrectPercentage <= 0.5) {
      this.resultBgColor = '#e73b3b';
      this.resultTitle = 'Non ci siamo...';
      this.resultText = 'Non hai raggiunto la sufficienza. Ti consigliamo di riguardare le lezioni inerenti questo capitolo prima di riprovare.';
    } else if (currentCorrectPercentage < 0.8) {
      this.resultBgColor = '#f47f37';
      this.resultTitle = 'Ci siamo quasi';
      this.resultText = 'Ancora uno sforzo! Con questo tasso di errore non sei pronto per l\'esame, ripassa il capitolo e continua ad allenarti.';
    } else if (currentCorrectPercentage < 1) {
      this.resultBgColor = '#66BB6A';
      this.resultTitle = 'Molto bene!';
      this.resultText = 'La tua preparazione su questo capitolo è quasi perfetta. Allenati ancora un po\' e sarai impeccabile.';
    } else {
      this.resultBgColor = '#41df9c';
      this.resultTitle = 'Perfetto!';
      this.resultText = 'Complimenti, questo capitolo non ha più segreti per te. Controlla la pagina Statistiche per scoprire i tuoi punti deboli e continuare ad allenarti.';
    }
  }

  calculateCorrectPercentage() {
    return this.correctAnswers / this.totalQuestions;
  }

  initializeComponent() {
    this.showMenu = true;
    this.actualQuestionIndex = 0;
    this.quizDataList = [];

    this.quizNumber = 0;
    this.questionsAnswered = 0;
    this.correctAnswers = 0;
    this.wrongAnswers = 0;

    this.isQuizFinished = false;
    this.quizFinished = false;
    this.currentQuestion = null;

    this.disableTopicSelection = false;
  }

  getQuizStyle() {
    if (this.currentQuestion) {
      if (this.currentQuestion.isCorrectAnswer !== undefined &&
        this.currentQuestion.isCorrectAnswer || (this.isQuizFinished && !this.showExplanation)) {
        return 'quiz-card-right';
      } else if (this.currentQuestion.isCorrectAnswer != null &&
        !this.currentQuestion.isCorrectAnswer) {
        return 'quiz-card-wrong';
      } else {
        return 'quiz-card';
      }
    } else {
      return 'quiz-card';
    }
  }

}
