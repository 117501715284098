import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-totalizer',
  templateUrl: './question-totalizer.component.html',
  styleUrls: ['./question-totalizer.component.scss']
})
export class QuestionTotalizerComponent implements OnInit {

  @Input() backgroundColor = '';
  @Input() titleClass = '';
  @Input() title = '';
  @Input() firstInfo = 0;
  @Input() secondInfo = 0;
  @Input() firstInfoDescription = '';
  @Input() secondInfoDescription = '';

  constructor() {}

  ngOnInit(): void {}

}
