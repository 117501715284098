import {animation, style, animate, trigger, transition, useAnimation, state} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('*=>*', [
    style({opacity: 0}),
    animate(250)
  ])
]);

export const fadeAnimationIn = trigger('fadeAnimationIn', [
  transition(':enter', [
    style({opacity: 0}),
    animate(250)
  ])
]);

export const inOutAnimation = trigger(
  'inOutAnimation',
  [
    transition(
      ':enter',
      [
        style({ height: 0, opacity: 0 }),
        animate('0.3s ease-out',
          style({ opacity: 1 }))
      ]
    ),
    /*transition(
        ':leave',
        [
          style({ height: 300, opacity: 1 }),
          animate('0.2s ease-in',
              style({ height: 0, opacity: 0 }))
        ]
    )*/
  ]
);

export const inOutExplanationAnimation = trigger(
  'inOutExplanationAnimation',
  [
    transition(
      ':enter',
      [
        style({ height: 0, opacity: 0 }),
        animate('2s ease-out',
          style({ height: 300, opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 1 }),
        animate('0.0s ease-in',
          style({ height: 0, opacity: 0 }))
      ]
    )
  ]
);

export const rotateCardAnimation =
  trigger('rotateCardAnimation', [
    state('false', style({transform: 'rotate(0)'})),
    state('true', style({
      transform: 'rotateY(-180deg)',
      transformStyle: 'preserve-3d',
      backfaceVisibility: 'visible'})),
    transition('true => false', animate('400ms ease-in-out')),
    transition('false => true', animate('400ms ease-in-out'))
  ]);
