import {Component, OnDestroy} from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '@app/_services';
import { User } from './_models';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy{

    subscription: Subscription;
    authenticationServiceSub: Subscription;

    items: MenuItem[];

    user: User;

    constructor(public breadcrumbService: BreadcrumbService, public app: AppComponent, public appMain: AppMainComponent, private authenticationService: AuthenticationService) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnInit(){
        this.authenticationServiceSub = this.authenticationService.user.subscribe(u => {
            this.user = u;
        })
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if(this.authenticationServiceSub){
            this.authenticationServiceSub.unsubscribe();
        }
    }

    logout(){
        this.authenticationService.logout();
    }
}
