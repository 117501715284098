<div class="p-grid quiz-container">
    <div class="p-col-12 p-lg-12 p-md-12 p-sm-12 quiz-card"
        style="display: flex;align-items: center; justify-content: center">
        <div *ngIf="!showExplanation && !isQuizFinished" style="width: 100%;">
            <!--TOPIC SELECTION-->
            <div class="p-grid p-jc-center exam-training-topic-container" *ngIf="showMenu">
                <div class="p-col-12 information-box">
                    <span>
                        <h2 style="margin-top: 0;">Simulazione d'esame.</h2>
                        <p>Totale di 30 domande da svolgere in 20 minuti</p>
                    </span>
                </div>
                <div class="p-col-12 p-lg-2 p-md-3 p-sm-12" style="text-align: center;width: auto">
                    <button pButton pRipple type="button" [disabled]="disableStartButton" label="Inizia la simulazione"
                        (click)="beginExam()" style="margin: 0.5%;">
                    </button>
                </div>
            </div>

            <!--QUESTION-->
            <div class="p-grid" *ngIf="!showMenu">
                <div class="p-col-6 p-lg-6 p-md-6 p-sm-6 quiz-timer">
                    <p class="quiz-clock-info-big quiz-status-info">
                        <i class="pi pi-clock" style="font-size: 2rem; margin-right: 0.5rem"></i>
                        <countdown #cd [config]="timerConfig" (event)="handleTimerEvent($event)"></countdown>
                    </p>
                    <p class="quiz-clock-info-small quiz-status-info">
                        <i class="pi pi-clock" style="font-size: 6.5vw; margin-right: 0.5rem"></i>
                        <countdown #cd [config]="timerConfig" (event)="handleTimerEvent($event)"></countdown>
                    </p>
                </div>
                <div class="p-col-6 p-lg-6 p-md-6 p-sm-6 quiz-timer">
                    <p class="quiz-status-info">Quiz {{this.quizNumber}}/{{this.totalQuestions}}</p>
                </div>
                <div class="p-col-12 divider-top">
                    <p-divider></p-divider>
                </div>
                <ng-container *ngIf="currentQuestion.quizData.image">
                    <div class="p-col-12 p-lg-3 p-md-3 box quiz-image-container">
                        <div class="box quiz-image">
                            <img *ngIf="currentQuestion && currentQuestion.quizData.image"
                                [src]="apiUrl + '/ExamQuiz/media/' + currentQuestion.quizData.id | authImage | async"
                                [@fadeAnimationIn]="currentQuestion">
                        </div>
                    </div>
                </ng-container>
                <div [ngClass]="{
                'p-col box': currentQuestion.quizData.image,
                'p-col-12 text-centered': !currentQuestion.quizData.image}">
                    <div class="box quiz-text" *ngIf="currentQuestion.quizData.image">
                        <p *ngIf="currentQuestion" [@fadeAnimationIn]="currentQuestion">
                            {{currentQuestion.quizData.questionText}}</p>
                    </div>
                    <div class="quiz-text-original-position" *ngIf="!currentQuestion.quizData.image">
                        <p *ngIf="currentQuestion" [@fadeAnimationIn]="currentQuestion">
                            {{currentQuestion.quizData.questionText}}</p>
                    </div>
                </div>
                <div class="p-col-12 divider-bottom">
                    <p-divider></p-divider>
                </div>
                <div class="p-col-12 box">
                    <div class="p-grid">
                        <div class="p-col-6 p-md-4 p-sm-12 p-md-offset-2 p-lg-offset-2 box padding">
                            <button pButton type="button" class="quiz-button-response p-button-success" label="Vero"
                                style="width: 100%;" icon="pi pi-check" (click)="answer(true)"
                                [disabled]="disableQuizAnswer"></button>
                        </div>
                        <div class="p-col-6 p-md-4 p-sm-12 box padding">
                            <button pButton type="button" class="quiz-button-response p-button-danger" label="Falso"
                                style="width: 100%;" icon="pi pi-times" (click)="answer(false)"
                                [disabled]="disableQuizAnswer"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- EXPLANATION SIDE OF THE CARD -->

        <div class="p-grid box test" *ngIf="showExplanation" style="width: 100%;">
            <div class="p-col-12 p-lg-4 p-md-4 p-sm-12 explanation-image box"
                *ngIf="this.currentQuestion.quizData.image">
                <img *ngIf="currentQuestion.quizResult"
                    [src]="apiUrl + '/ExamQuiz/media/' + currentQuestion.quizData.id | authImage | async">
            </div>
            <div [ngClass]="{
            'p-col-12 p-lg-8 p-md-8 p-sm-12 box': currentQuestion.quizData.image,
            'p-col-12': !currentQuestion.quizData.image}">
                <div class="explanation-text" style="display: flex; flex-direction: column; justify-content: center">
                    <p><b>{{this.currentQuestion.quizData.questionText}}</b></p>
                    <p *ngIf="this.currentQuestion.answer == true">
                        <b>Spiegazione:</b> {{this.currentQuestion.quizResult.explanationText}}
                    </p>
                    <p *ngIf="this.currentQuestion.answer == false">
                        <b>Spiegazione:</b> Quanto sopra affermato è vero.
                    </p>
                </div>
            </div>
            <div style="width: 100%;">
                <div style="display: flex; justify-content: center">
                    <div style="margin-left: 0; text-align: center" [ngClass]="{
                        'p-col-12 p-lg-4 p-md-4 p-sm-12 p-md-offset-4 p-lg-offset-4': true}">
                        <button pButton pRipple type="button" label="Continua" (click)="backToQuiz()"
                            style=" margin: 0.5%;">
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- STATISTICS -->
        <div class="p-grid p-jc-center box" style="text-align: center;" *ngIf="isQuizFinished && !showExplanation">
            <!--div style="margin-top: 2%"-->
            <!--div *ngIf="wrongQuizList.length != 0"-->
            <div class="p-col-12 box" *ngIf="rightQuizList.length >= quizIdList.length-3">
                <h1 class="okmessage">ESAME SUPERATO !</h1>
            </div>
            <div class="p-col-12 box" *ngIf="rightQuizList.length < quizIdList.length-3">
                <h1 class="komessage">NON HAI SUPERATO L'ESAME !</h1>
            </div>


            <div *ngIf="rightQuizList.length == quizIdList.length">
                <h2 style="color: #fff;">Complimenti. Hai risposto correttamente a tutte le domande.</h2>
            </div>

            <div *ngIf="rightQuizList.length == 0 && wrongQuizList.length == 0">
                <h2 style="color: #fff;">Riprova con un altra simulazione d'esame.</h2>
            </div>

            <div class="p-col-12 box" *ngIf="wrongQuizList.length != 0">
                <h2 style="color: #fff;">Le risposte alle seguenti domande sono sbagliate: clicca per conoscere la
                    spiegazione.</h2>
            </div>
            <div *ngFor="let wrongQuiz of wrongQuizList" (click)="showExplanationFinal(wrongQuiz)"
                class="card1 p-col-12 p-lg-2 p-md-2 p-sm-12 box pCard1">
                <h3># Quesito {{getQuizIndex(wrongQuiz.quizData.id) + 1}}</h3>
                <span>
                    Hai risposto: {{translateAnswer(wrongQuiz.answer)}}
                    <i [ngClass]="wrongQuiz.answer ? 'pi pi-check' : 'pi pi-times'"></i>
                </span>
                <div class="go-corner" href="#">
                    <div class="go-arrow">
                        <p>→</p>
                    </div>
                </div>
            </div>
            <!--/div-->
            <div class="p-col-12">
                <button pButton pRipple type="button" label="Nuova simulazione d'esame" icon="pi pi-replay"
                    (click)="this.initializeComponent()" style=" margin: 0.5%;">
                </button>
            </div>
            <!--/div-->
        </div>
    </div>
</div>