import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api/primeng-api';
import { AppMainComponent } from '@app/app.main.component';
import { LessonsService } from '@app/_services/lessons.service';
import { DomHandler } from 'primeng/dom';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PanelMenu } from 'primeng/panelmenu';
import { LessonTopicService } from '@app/_services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {

  // This to interact with panelMenu method handleClick(), in order to refresh panelMenu template
  @ViewChild('panelMenu') panelMenu: PanelMenu;

  items: MenuItem[] = [];
  scrollable = true;
  pendingScrollable = null;

  lessonsList: MenuItem[] = [];

  constructor(
      public appMain: AppMainComponent,
      private lessonsService: LessonsService,
      private lessonTopicService: LessonTopicService,
      private el: ElementRef,
      private router: Router) {

    this.lessonsService.getAllLessonsIncludeTopics().subscribe(lessons => {
      lessons.forEach(l => {
        const subLessons = [];
        l.lessonTopics.forEach(lt => {
          const item = {
            id: lt.id.toString(),
            label: lt.label,
            icon: 'pi pi-fw pi-bookmark',
            routerLink: '/video-player/' + lt.id ,
            command: (event) => this.handleMenuClick(event),
            styleClass: (lt.viewed ? 'lesson-viewed' : '')
          };
          subLessons.push(item);
        });

        const les = {
          id: l.id.toString(),
          label: l.label,
          icon: l.icon,
          items: subLessons,
          expanded: false,
          styleClass: (this.lessonTopicService.areAllLessonTopicViewed(subLessons) ? 'lesson-viewed' : '')
        };

        this.lessonsList.push(les);
      });
      this.populateItemsList();

      // Check if a scroll event is waiting to be executed
      if (this.pendingScrollable) {
        this.scrollToSelectedRoute(this.pendingScrollable);
      }
    });

    // Subscribe for routing events
    router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart && (routerEvent.navigationTrigger === 'popstate' /*|| this.scrollable*/)){
        const routeUrl = routerEvent.url;
      }

      if (routerEvent instanceof NavigationEnd /*&& !this.submenuRouting && this.scrollable*/){
        setTimeout(() => {
          this.scrollToSelectedRoute(routerEvent);
        }, 100);
      }
    });
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
  }

  handleMenuClick($event) {
    if (this.appMain.isMobile()) {
      this.appMain.onMenuButtonClick($event.originalEvent);
    }
  }

  scrollToSelectedRoute(routerEvent) {
    // Decode the component you're trying to reach
    switch (routerEvent.url.split('/')[1]) {
      case 'video-player': {
        this.handleVideoPlayerScrolling(routerEvent);
        break;
      }
      case '': {
        this.collapseAll();
        // In case home, expand lesson menu
        if (this.items.find(item => item.id === 'lessons')) {
          this.items.find(item => item.id === 'lessons').expanded = true;
          this.refreshPanelMenu();
        }
        break;
      }
      default: {
        this.collapseAll();
        this.refreshPanelMenu();
        break;
      }
    }
  }

  populateItemsList() {
    this.items = [
      {
        id: 'statistics',
        label: 'Statistiche',
        icon: 'pi pi-chart-bar',
        command: (event) => this.handleMenuClick(event),
        routerLink: '/home'
      },
      {
        id: 'lessons',
        label: 'Lezioni',
        icon: 'pi pi-pw pi-file',
        items: this.lessonsList,
        expanded: false
      },
      {
        id: 'training',
        label: 'Allenamento lezioni',
        icon: 'pi pi-pw pi-pencil',
        routerLink: '/exam-training',
        expanded: false,
        command: (event) => this.handleMenuClick(event),
        styleClass: '.p-menuitem-link-active'
      },
      {
        id: 'simulation',
        label: 'Simulazione esame',
        icon: 'pi pi-pw pi-id-card',
        routerLink: '/exam-simulation',
        expanded: false,
        command: (event) => this.handleMenuClick(event),
      },
      {
        id: 'tutor-contact',
        label: 'Contatta il tutor',
        icon: 'pi pi-shield',
        url: 'https://api.whatsapp.com/send?phone=393516582078&text=Salve',
        target: '_blank'
      }
    ];

    this.lessonsService.setItems(this.items);
    // this is in order to pass the actual component to child, to have a refresh on the menu
    this.lessonsService.setMenuComponent(this);
  }

  // Return lessonId starting from lessonTopicId
  getLessonIdFromLessonTopicId(lessonTopicId: number): string {
    for (const lesson of this.items.find(item => item.id === 'lessons').items) {
      const lessonTopic = lesson.items.find(lt => lt.id === lessonTopicId.toString());
      if (lessonTopic) {
        return lesson.id;
      }
    }
    return null;
  }

  // Panel menu refresh template only when receive an event. With dummyEvent, we simulate this to happen
  refreshPanelMenu() {
    const dummyEvent = new Event('test');
    this.panelMenu.handleClick(dummyEvent, this.panelMenu);
  }

  collapseAll() {
    this.panelMenu.model.forEach(item => {
      item.expanded = false;
      if (item.items) {
        item.items.forEach(subItem => {
          subItem.expanded = false;
        });
      }
    });
  }

  applyStyle() {
    this.panelMenu.model[0].items[0].items[0].styleClass = 'lesson-viewed';
    this.refreshPanelMenu();
  }

  expand(panelMenu: PanelMenu, event) {
    panelMenu.collapseAll();
    this.items.find(it => it.id === '1').expanded = true;
    this.items[1].expanded = true;
    this.items.push(
      {
        id: 'saas',
        label: 'asd',
        icon: 'pi pi-pw pi-file',
        expanded: true
      }
    );
    panelMenu.handleClick(event, panelMenu);
  }

  handleVideoPlayerScrolling(routerEvent) {
    // The try catch is to handle the possibility of scrolling to video before loading of the menu items
    try {
      // Collapse all
      this.collapseAll();
      // Expand lessons item
      this.items.find(item => item.id === 'lessons').expanded = true;
      // Retrieve lessonId from lessonTopicId
      const lessonId = this.getLessonIdFromLessonTopicId(routerEvent.url.split('/')[2]);
      // this.items.find(item => item.id === 'lessons').expanded = true;
      // Expand lessonTopic based on lessonId
      this.items.find(item => item.id === 'lessons').items.find(l => l.id === lessonId).expanded = true;
      const routeEl = DomHandler.findSingle(this.el.nativeElement, '.p-menuitem-link-active');

      // Refresh panelMenu Template
      this.refreshPanelMenu();
      if (routeEl) {
        routeEl.scrollIntoView({inline: 'start'});
      }
      this.pendingScrollable = null;

    } catch (e) {
      console.error(e);
      // This error is catched if data are not loaded yet. Is used to retry the scrolling
      this.pendingScrollable = routerEvent;
    }
  }
}
