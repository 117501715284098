<div class="card" [ngStyle]="{'background-color': backgroundColor ? backgroundColor : 'white !important' }">
    <div class="p-grid">
        <div class="p-col-12">
            <h4 class="title">{{title}}</h4>
        </div>
    </div>
    <ng-container *ngIf="this.data.datasets[0].data.length == 0 && this.data.datasets[1].data.length == 0">
        <span>{{explanation}}</span>
    </ng-container>
    <ng-container *ngIf="this.data.datasets[0].data.length !== 0 && this.data.datasets[1].data.length !== 0">
        <p-chart type="bar" [height]="200" [data]="data" [options]="graphOptions"></p-chart>
    </ng-container>
</div>
