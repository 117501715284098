import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-answers-board',
  templateUrl: './answers-board.component.html',
  styleUrls: ['./answers-board.component.scss']
})
export class AnswersBoardComponent implements OnInit {

  @Input() data = [];
  @Input() title = '';
  @Input() subtitle = '';
  @Input() backgroundColor = '';
  @Input() explanation = '';
  @Input() scrollHeight = '20vh';

  sortOptions = [
    { label: 'ordina per risultati migiori', value: 'asc' },
    { label: 'ordina per risultati peggiori', value: 'desc' }
  ];

  selectedSorting = 'asc';

  constructor() {
  }

  ngOnInit(): void {}

  getWidth(r) {
    return r.rank * 100 + '%';
  }

  onSortChange(event) {
    if (event.value === 'asc') {
      this.sort(-1);
    } else if (event.value === 'desc') {
      this.sort(1);
    }
  }

  sort(order: number): void {
    const temp = [...this.data];

    temp.sort((data1, data2) => {
      const value1 = data1.rank;
      const value2 = data2.rank;
      const result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return order * result;
    });

    this.data = temp;
  }
}
