import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LessonTopicModel } from '@app/_models/lesson-topic.model';
import { environment } from '@environments/environment';
import { MenuItem } from 'primeng/api/primeng-api';

@Injectable({
  providedIn: 'root'
})
export class LessonTopicService {

  constructor(private http: HttpClient) { }

  getTopicById(id: number) {
    return this.http.get<LessonTopicModel>(`${environment.apiUrl}/LessonTopic/${id}`);
  }

  setLessonTopicAsViewed(lessonTopicId: number) {
    return this.http.post<LessonTopicModel>(`${environment.apiUrl}/LessonTopic/${lessonTopicId}/completed`, null);
  }

  areAllLessonTopicViewed(lessonTopic: MenuItem[]): boolean {
    const lessonTopicSize = lessonTopic.length;
    let lessonTopicViewed = 0;

    lessonTopic.forEach(lt => {
      if (lt.styleClass !== '') {
        lessonTopicViewed++;
      }
    });

    if (lessonTopicViewed === lessonTopicSize) {
      return true;
    } else {
      return false;
    }
  }
}
