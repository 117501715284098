import { Component } from '@angular/core';
import {AppComponent} from './app.component';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
  version: string;
  
  constructor(public app: AppComponent) {
    this.version = environment.version;
  }

}
