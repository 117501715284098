import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {NoteModel, NoteRequestModel} from '@app/_models/note.model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(
      private http: HttpClient
  ) { }

  getAllNotes() {
    return this.http.get<NoteModel[]>(`${environment.apiUrl}/LessonNote`);
    /*return this.http
        .get('assets/json/notes.json', {withCredentials: true});*/
  }

  getNotesByTopicId(topicId: number) {
    return this.http.get<NoteModel[]>(`${environment.apiUrl}/LessonNote/get-lesson-notes-by-topic-id/` + topicId);
  }

  deleteNote(noteId: number) {
    return this.http.delete(`${environment.apiUrl}/LessonNote/` + noteId);
  }

  updateNote(note: NoteModel) {
    return this.http.put(`${environment.apiUrl}/LessonNote/` + note.id, note);
  }

  createNote(note: NoteRequestModel) {
    return this.http.post(`${environment.apiUrl}/LessonNote/`, note);
  }
}
