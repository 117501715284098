import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-countdown-button',
  templateUrl: './countdown-button.component.html',
  styleUrls: ['./countdown-button.component.scss']
})
export class CountdownButtonComponent implements OnInit, OnChanges {

  @ViewChild('buttonCountdown', { static: false }) private countdown: CountdownComponent;

  @Input() label: string;
  @Input() duration = 0;
  @Input() timerFormat = 's';
  @Input() countdownEnabled: boolean;
  @Output() timerOver = new EventEmitter<boolean>();
  @Output() buttonClicked = new EventEmitter<boolean>();

  percenteageLeft = 100;

  timerConfig = {
    leftTime: this.duration,
    format: this.timerFormat,
    notify: 0
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.timerConfig.leftTime = this.duration;
    this.timerConfig.format = this.timerFormat;
  }

  timerChange($event) {
    //console.log($event);
    this.percenteageLeft = this.getPercenteageLeft($event.left);
    if ($event.action === 'done') {
      setTimeout(() => {
        this.timerOver.emit(true);
      }, 1000);
    }
  }

  getPercenteageLeft(value) {
    return value * 100 / (this.duration * 1000);
  }
}
