import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';
import { AppComponent } from '@app/app.component';
import { ForgotPassword, ResetPassword, ValidateResetToken } from '@app/_models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;  
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  polling: any;
  bgcounter = 1;
  bgpath: string = "assets/layout/images/pages/bg-login.jpg";

  loginState = 0;
  lostPasswordForm: FormGroup;
  validatePasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  validateCodeError = false;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      public app: AppComponent
  ) { 
      // redirect to home if already logged in
      if (this.authenticationService.userValue) { 
          this.router.navigate(['/']);
      }
  }

  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          password: ['', Validators.required]
      });

      this.lostPasswordForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
    });

    this.validatePasswordForm = this.formBuilder.group({
        token: ['', Validators.required]
    });

    this.resetPasswordForm = this.formBuilder.group({
        token: [''],
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(8), this.matchValues('password')]]
    });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      this.polling = setInterval(() => {
        this.rotateBg();
      }, 5000);
  }

  rotateBg(){
    this.bgcounter++;
    if (this.bgcounter>4) this.bgcounter = 1;
    this.bgpath = "assets/layout/images/pages/bg-login-"+this.bgcounter+".jpg";
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  get f2() { return this.lostPasswordForm.controls; }
  get f3() { return this.validatePasswordForm.controls; }
  get f4() { return this.resetPasswordForm.controls; }

  ngOnDestroy() {
    if (this.polling){
        clearInterval(this.polling);
    }
  }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      this.authenticationService.login(this.f.email.value, this.f.password.value)
          .pipe(first())
          .subscribe({
              next: () => {
                  this.router.navigate([this.returnUrl]);
              },
              error: error => {
                  this.error = error;
                  this.loading = false;
              }
          });
  }

  onLostPassword(){
      this.loginState = 1;      
  }

  onLostPasswordEmailSubmit(){
      
      // stop here if form is invalid
      if (this.lostPasswordForm.invalid) {
        return;
      }

      let model: ForgotPassword  = {
          email: this.f2.email.value
      };
      
      this.authenticationService.forgotPassword(model).subscribe(_ => {
        this.loginState = 2;
      });
  }

  onLostPasswordValidateSubmit(){

      // stop here if form is invalid
      if (this.validatePasswordForm.invalid) {
        return;
      }

      let model: ValidateResetToken  = {
          token: this.f3.token.value
      };
    
      this.authenticationService.validateResetToken(model).subscribe(
          success => {
            this.validateCodeError = false;
            this.loginState = 3;
          },
          error => {
            this.validateCodeError = true;
          }
      );
      
  }

  onLostPasswordChangeSubmit(){

      // stop here if form is invalid
      if (this.resetPasswordForm.invalid) {
        return;
      }

      if (this.f4.password.value != this.f4.confirmPassword.value){
          return;
      }

      let model: ResetPassword  = {
        token: this.f3.token.value,
        password: this.f4.password.value,
        confirmPassword: this.f4.confirmPassword.value
      };

      this.authenticationService.resetPassword(model).subscribe(
        success => {
          this.validateCodeError = false;
          this.loginState = 0;
          this.loginForm.reset();
        },
        error => {
        }
    );
      
  }

  onLostPasswordExit(){
    this.loginState = 0;
    this.loginForm.reset();
  }

  matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
}
}