import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  @Input() title = '';
  @Input() backgroundColor = '';
  @Input() progressValue = 0;
  @Input() explanation = '';
  @Input() enable = false;

  constructor() {}

  ngOnInit(): void {}
}
