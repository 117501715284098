import { QuizDataModel } from '@app/_models/quiz-data.model';
import { QuizResultModel } from '@app/_models/quiz-result.model';

export class QuizDataClientModel {
  quizData: QuizDataModel = new QuizDataModel();
  answer: boolean;
  answered = false;
  isCorrectAnswer: boolean = null;
  quizResult?: QuizResultModel;
}

export class QuizListModel {
  quizId: number;
}
