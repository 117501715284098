import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  /**
   * General purpose methods can be defined in this service
   */
  constructor(private datePipe: DatePipe) { }

  generateSessionToken() {
    //const myDate = new Date().toLocaleString();
    //let ret = this.datePipe.transform(myDate, 'yyyyMMddHHmmss');
    let ret = this.yyyymmddhhmmss();
    ret = ret.concat(this.randomStringGenerator(10));
    return ret;
  }

  private randomStringGenerator(length: number) {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private yyyymmddhhmmss() {
    var now = new Date();
    var y = now.getFullYear();
    var m = now.getMonth() + 1;
    var d = now.getDate();
    var h = now.getHours();
    var mm = now.getMinutes();
    var s = now.getSeconds();
    return '' + y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d + (h < 10 ? '0' : '') + h + (mm < 10 ? '0' : '') + mm + (s < 10 ? '0' : '') + s;
  }
}
