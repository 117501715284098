<button pButton pRipple type="button"
    label='{{label}}'
    style="width: 99%; margin: 0.5%; display: inline; height: 100%"
    (click)="buttonClicked.emit(true)">
    <span>
        <p-progressBar
                [value]="percenteageLeft"
                [showValue]="false"
                styleClass="custom-progress"
                styleClass="custom-progress"
                *ngIf="countdownEnabled"
        ></p-progressBar>
    </span>
</button>
<countdown #buttonCountdown [config]="timerConfig" (event)="timerChange($event)" style="display: none;" *ngIf="countdownEnabled"></countdown>
