import { AfterViewInit, EventEmitter, OnChanges } from '@angular/core';
import { SimpleChanges, Input, Output, Component, OnInit } from '@angular/core';
import { NoteModel } from '@app/_models/note.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CuePoint } from '@app/_models/cuepoint.model';
import { min } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  providers: [ConfirmationService]
})
export class NotesComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() elapsedTime: number;
  @Input() noteReached: NoteModel;
  @Input() cuePointList: CuePoint[];
  @Output() noteSet: EventEmitter<CuePoint> = new EventEmitter();
  @Output() noteUpdated: EventEmitter<CuePoint> = new EventEmitter();
  @Output() noteRemoved: EventEmitter<CuePoint> = new EventEmitter();

  // This output is used to interact with the player on the parent.
  @Output() videoCommand: EventEmitter<string> = new EventEmitter();
  @Output() goToSeconds: EventEmitter<number> = new EventEmitter();

  noteEditing = false;
  highlightNote = false;
  newNoteInserted = false;
  showNoteDiv = false;

  selectedNote: CuePoint = new CuePoint();

  bgnote = {'background': '#fdfcbb'};
  bgnote2 = {'background-color': 'white'};

  noteForm: FormGroup;

  constructor(
      private messageService: MessageService,
      private confirmationService: ConfirmationService) {
    this.selectedNote.data = new NoteModel();

    this.noteForm = new FormGroup({
      note: new FormControl('')
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.noteReached) {
      if (!this.newNoteInserted) {
        this.selectedNote = changes.noteReached.currentValue;
        this.showNoteDiv = true;
      }

      this.newNoteInserted = false;
      this.noteEditing = true;
    }

    if (changes.cuePointList) {
      this.showNoteDiv = false;
      this.selectedNote = new CuePoint();
      if (this.cuePointList.filter(c => c.data.global).length !== 0) {
        this.selectedNote = this.cuePointList.filter(c => c.data.global)[0];
        this.showNoteDiv = true;
      }
    }
}

  noteCardClicked($event) {
    this.selectedNote = new CuePoint();
    $event.stopPropagation();
  }

  addNote() {
    this.showNoteDiv = true;
    this.noteEditing = false;
    this.videoCommand.emit('PAUSE');
    const newNote = new CuePoint();
    newNote.data.text = '';
    newNote.data.start = this.elapsedTime;
    this.selectedNote = newNote;
  }

  noteSelected(cuePoint: CuePoint) {
    this.noteForm.markAsPristine();
    // select-unselect note
    if (cuePoint.id && (cuePoint.id === this.selectedNote.id)) {
      this.showNoteDiv = false;
      this.selectedNote = new CuePoint();
      this.noteEditing = false;
    }
    else {
      this.showNoteDiv = true;
      this.selectedNote = cuePoint;
      this.noteEditing = true;
    }
  }

  noteInserted() {
    if (!this.noteEditing) {
      this.noteSet.emit(this.selectedNote);
      this.newNoteInserted = true;
    }

    else {
      this.noteUpdated.emit(this.selectedNote);
    }

    this.selectedNote = new CuePoint();
    this.showNoteDiv = false;
  }

  goToCutPoint() {
    this.goToSeconds.emit(this.selectedNote.data.start);
  }

  removeNote($note) {
    this.videoCommand.emit('PAUSE');
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler cancellare questa nota?',
      accept: () => {
        this.noteRemoved.emit($note);
      }
    });
  }

  printTime(time){
    let totalSeconds = time;
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    if (hours == 0 ){
      return minutes+":"+seconds;
    }
    else{
      return hours+":"+minutes+":"+seconds;
    }

  }
}
