import { Component, Input, OnInit } from '@angular/core';
import {QuizDetailStatisticsData} from "@app/_component/dashboard/dashboard.component";

@Component({
  selector: 'app-monthly-quiz',
  templateUrl: './monthly-quiz.component.html',
  styleUrls: ['./monthly-quiz.component.scss']
})
export class MonthlyQuizComponent implements OnInit {

  @Input() title = '';
  @Input() data = new QuizDetailStatisticsData();
  @Input() graphOptions = {};
  @Input() backgroundColor = '';
  @Input() explanation = '';

  constructor() {}

  ngOnInit(): void {}

}
