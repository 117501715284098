<div class="card widget-tasks" [ngStyle]="{'background-color': backgroundColor ? backgroundColor : 'white !important' }">
    <h4>{{title}}</h4>
    <p>{{subtitle}}</p>
    <ng-container *ngIf="!this.data || !this.data.length">
        <span>{{explanation}}</span>
    </ng-container>
    <ng-container *ngIf="this.data && this.data.length">
        <p-dropdown
                [options]="sortOptions"
                placeholder="Ordina per"
                (onChange)="onSortChange($event)"
                [(ngModel)]="selectedSorting"
                [style]="{'min-width':'10em'}"></p-dropdown>
        <p-virtualScroller [value]="this.data" [scrollHeight]="this.scrollHeight" [itemSize]="60">
            <h4>{{title}}</h4>
            <p>{{subtitle}}</p>
            <ng-template let-dat pTemplate="item" let-i="index">
                <div class="task">
                    <div class="task-name">{{dat.title}}</div>
                    <div class="task-progress">
                        <div style="background: {{dat.background}}; width: {{getWidth(dat)}};"></div>
                    </div>
                </div>
            </ng-template>
        </p-virtualScroller>
    </ng-container>
</div>
