import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { LessonStatisticModel } from '@app/_models/lesson-statistic.model';
import { ExamStatisticModel } from '@app/_models/exam-statistic.model';
import { ExamDetailModel } from '@app/_models/exam-detail.model';
import { QuizDetailStatisticModel } from '@app/_models/quiz-detail-statistic.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient) { }

  getLessonStatistics() {
    return this.http.get<LessonStatisticModel>(`${environment.apiUrl}/Statistic/lessons`);
  }

  getExamTrainingStatistics() {
    return this.http.get<ExamStatisticModel>(`${environment.apiUrl}/Statistic/examtrainings`);
  }

  getExamSimulationStatistics() {
    return this.http.get<ExamStatisticModel>(`${environment.apiUrl}/Statistic/examsimulations`);
  }

  getExamTrainingDetailStatistics() {
    return this.http.get<ExamDetailModel[]>(`${environment.apiUrl}/Statistic/examtrainingdetail`);
  }

  getExamSimulationDetailStatistics() {
    return this.http.get<ExamDetailModel[]>(`${environment.apiUrl}/Statistic/examsimulationdetail`);
  }

  getQuizDetailStatistics() {
    return this.http.get<QuizDetailStatisticModel[]>(`${environment.apiUrl}/Statistic/quizdetail`);
  }
}
