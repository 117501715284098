import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LessonsModel} from '@app/_models/lessons.model';
import {environment} from '@environments/environment';
import {MenuItem} from 'primeng/api/primeng-api';
import {LessonTopicService} from '@app/_services/lesson-topic.service';
import {SidebarComponent} from '@app/_component/sidebar/sidebar.component';


@Injectable({
  providedIn: 'root'
})
export class LessonsService {

  private items: MenuItem[] = [];
  private menuComponent: SidebarComponent;

  constructor(
    private http: HttpClient,
    private lessonTopicService: LessonTopicService) { }

  getAllLessonsIncludeTopics() {
      return this.http.get<LessonsModel[]>(`${environment.apiUrl}/Lesson/get-all-include-lesson-topic`);
  }

  setItems(items) {
    this.items = items;
  }

  setMenuComponent(menuComponent) {
    this.menuComponent = menuComponent;
  }

  getNextLessonTopicId(currentLessonTopicId: number): string {
    //console.log('Getting next lesson from id ', currentLessonTopicId);
    const lessonList = this.items.find(item => item.id === 'lessons');
    let index = 0;
    let lessonIndex = 0;
    let hasNoNextTopic = false;

    for (const lesson of lessonList.items) {
      index = 0;
      for (const lessonTopic of lesson.items) {
        // If lessonTopic found in current lesson
        if (lessonTopic.id === currentLessonTopicId.toString()) {
          // Check if next lessonTopic exist in current lesson
          if (lesson.items[index + 1]) {
            return lesson.items[index + 1].id;
          } else {
            // Or take the first topic of the next lesson
            hasNoNextTopic = true;
          }
        } else {
          index++;
        }
      }
      lessonIndex ++;
      if (hasNoNextTopic) {
        if (lessonList.items[lessonIndex] && lessonList.items[lessonIndex].items.length !== 0) {
          return lessonList.items[lessonIndex].items[0].id;
        }
      }
    }
  }

  setLessonTopicAsViewed(lessonTopicId: number) {
    // Mark the lessonTopic as viewed for the current user
    this.lessonTopicService.setLessonTopicAsViewed(lessonTopicId).subscribe(res => {
      //console.log('Lesson marked', res);
    });
    const lessonList = this.items.find(item => item.id === 'lessons');
    for (const lesson of lessonList.items) {

      if (lesson.items.find(lessonTopic => lessonTopic.id === lessonTopicId.toString())) {
        lesson.items.find(lessonTopic => lessonTopic.id === lessonTopicId.toString()).styleClass = 'lesson-viewed';

        // Apply style on lesson level if all lessonTopic viewed
        lesson.styleClass = (this.lessonTopicService.areAllLessonTopicViewed(lesson.items) ? 'lesson-viewed' : '');
        this.menuComponent.refreshPanelMenu();
        return;
      }
    }
  }
}
