import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flex-container',
  templateUrl: './flex-container.component.html',
  styleUrls: ['./flex-container.component.scss']
})
export class FlexContainerComponent implements OnInit {

  item = {
    header: 'Simple Card',
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!`
  };

  items = [];

  constructor() { }

  ngOnInit(): void {
    for (let i = 0; i < 3; i++) {
      this.items.push(this.item);
    }
  }

  addItem() {
    this.items.push(this.item);
  }

  removeItem() {
    this.items.pop();
  }
}
