import {LessonsModel} from '@app/_models/lessons.model';

export class LessonTopicModel {
    id: number;
    // lesson: LessonsModel;
    label: string;
    videoUrl: string;
    start: number;
    stop: number;
    icon: string;
    quiz: boolean;
    viewed = false;
}
