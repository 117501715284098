<div class="card widget-overview-box widget-overview-box-1 no-gutter"
     [ngStyle]="{'background-color': backgroundColor ? backgroundColor : 'white !important'}">
    <span class="overview-icon">
        <i [ngClass]="titleClass"></i>
    </span>
    <span class="overview-title">{{title}}</span>
    <div class="p-grid overview-detail">
        <div class="p-col-6">
            <div class="overview-number">{{firstInfo}}</div>
            <div class="overview-subtext">{{firstInfoDescription}}</div>
        </div>
        <div class="p-col-6">
            <div class="overview-number">{{secondInfo}}</div>
            <div class="overview-subtext">{{secondInfoDescription}}</div>
        </div>
    </div>
</div>
