import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from '@app/_animations/animations';
import { StatisticsService } from '@app/_services/statistics.service';
import { LessonStatisticModel } from '@app/_models/lesson-statistic.model';
import { ExamStatisticModel } from '@app/_models/exam-statistic.model';
import { ExamDetailModel } from '@app/_models/exam-detail.model';
import { QuizDetailStatisticModel } from '@app/_models/quiz-detail-statistic.model';
import { AuthenticationService } from '@app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class DashboardComponent implements OnInit {

  examTrainingProgress = 27;
  examSimulationProgress = 35;

  lessonStatistics: LessonStatisticModel = new LessonStatisticModel();
  examTrainingStatistics: ExamStatisticModel = new ExamStatisticModel();
  examSimulationStatistics: ExamStatisticModel = new ExamStatisticModel();

  examTrainingDetailStatistics: ExamDetailModel[] = [];
  examSimulationDetailStatistics: ExamDetailModel[] = [];
  examTrainingDetailStatisticsData = [];
  examSimulationDetailStatisticsData = [];
  quizDetailStatistics: QuizDetailStatisticModel[] = [];

  progressValueLesson: number;
  progressValueTraining: number;
  progressValueSimulation: number;

  quizDetailStatisticsData: QuizDetailStatisticsData = new QuizDetailStatisticsData();

  colors = [
    '#64B5F6',
    '#A5D6A7',
    '#7986CB',
    '#9575CD'
  ];


  graphOptions = {
    legend: {
      labels: {
        fontColor: '#495057'
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: '#495057'
        }
      }],
      yAxes: [{
        ticks: {
          fontColor: '#495057'
        }
      }]
    }
  };

  trialMode:boolean = false;
  authenticationServiceSub: Subscription;

  constructor(
    private statisticsService: StatisticsService, private authenticationService: AuthenticationService
  ) {

    this.statisticsService.getLessonStatistics().subscribe(res => {
      this.lessonStatistics = res;
      this.progressValueLesson = this.makeCalculation(this.lessonStatistics.completed, this.lessonStatistics.total);
    });

    /*this.statisticsService.getExamTrainingStatistics().subscribe(res => {
      if (res) {
        this.examSimulationStatistics = res;
        this.progressValueTraining = this.getExamStatistics('training');
        console.log("T:"+this.progressValueTraining);
      }
    });*/

    this.statisticsService.getExamSimulationStatistics().subscribe(res => {
      if (res) {
        this.examSimulationStatistics = res;
        this.progressValueSimulation = this.makeCalculation(this.examSimulationStatistics.pass, this.examSimulationStatistics.total);
      }
    });

    this.statisticsService.getExamTrainingDetailStatistics()
      .subscribe(res => {
      if (res) {
        this.examTrainingDetailStatistics = res
          .filter(r => r.count !== 0)
          .sort((one, two) => (one.rank > two.rank ? -1 : 1));
        this.examTrainingDetailStatistics.forEach(r => {
          const el = {
            title: r.topic,
            background: this.colors[Math.floor(Math.random() * this.colors.length)],
            rank: r.rank
          };
          this.examTrainingDetailStatisticsData.push(el);
        });
      }
    });

    this.statisticsService.getExamSimulationDetailStatistics()
      .subscribe(res => {
        if (res) {
          this.examSimulationDetailStatistics = res
            .filter(r => r.count !== 0)
            .sort((one, two) => (one.rank > two.rank ? -1 : 1));
          this.examSimulationDetailStatistics.forEach(r => {
            const el = {
              title: r.topic,
              background: this.colors[Math.floor(Math.random() * this.colors.length)],
              rank: r.rank
            };
            this.examSimulationDetailStatisticsData.push(el);
          });
        }
      });

    this.statisticsService.getQuizDetailStatistics().subscribe(res => {
      if (res) {
        this.quizDetailStatistics = res;
        this.quizDetailStatistics.forEach(r => {
          this.quizDetailStatisticsData.labels.push(this.getMonth(r.month));
          this.quizDetailStatisticsData.datasets[0].data.push(r.completed);
          this.quizDetailStatisticsData.datasets[1].data.push(r.score);
        });

        this.quizDetailStatisticsData =  { ...this.quizDetailStatisticsData };
      }
    });

  }

  ngOnInit(): void { 
    this.authenticationServiceSub = this.authenticationService.user.subscribe(u => {
      if (u.role== "Trial" ){
        this.trialMode = true;
      }
      else {
        this.trialMode = false;
      }
    })
   }

  private makeCalculation(firstValue: number, secondValue: number) {
    const calculation = (
      (firstValue / secondValue) * 100
    );

    if (calculation) {
      return calculation;
    } else {
      return 0;
    }
  }

  ngOnDestroy() {
    if(this.authenticationServiceSub){
        this.authenticationServiceSub.unsubscribe();
    }
  }

  private getMonth(num: number): string {
    switch (num) {
      case 1: {
        return 'Gennaio';
      }
      case 2: {
        return 'Febbraio';
      }
      case 3: {
        return 'Marzo';
      }
      case 4: {
        return 'Aprile';
      }
      case 5: {
        return 'Maggio';
      }
      case 6: {
        return 'Giugno';
      }
      case 7: {
        return 'Luglio';
      }
      case 8: {
        return 'Agosto';
      }
      case 9: {
        return 'Settembre';
      }
      case 10: {
        return 'Ottobre';
      }
      case 11: {
        return 'Novembre';
      }
      case 12: {
        return 'Dicembre';
      }
    }
  }
}

export class QuizDetailStatisticsData {
  labels: string[] = [];
  datasets: Data[] = [
    {
      label: 'Totali',
      backgroundColor: '#42A5F5',
      data: []
    },
    {
      label: 'Corretti',
      backgroundColor: '#FFA726',
      data: []
    }
  ];
}

export class Data {
  label = '';
  backgroundColor = '';
  data: number[] = [];
}
