import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';

import { AuthGuard } from '@app/_helpers';
import { LoginComponent } from '@app/login/login.component';
import { Role } from '@app//_models';
import { VideoPlayerComponent } from '@app/_component/video-player/video-player.component';
import { FlexContainerComponent } from '@app/_component/flex-container/flex-container.component';
import { QuizComponent } from '@app/_component/quiz/quiz.component';
import { ExamTrainingComponent } from '@app/_component/exam-training/exam-training.component';
import { ExamSimulationComponent } from '@app/_component/exam-simulation/exam-simulation.component';
import { DashboardComponent } from '@app/_component/dashboard/dashboard.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent, canActivate: [AuthGuard],
                children: [
                    {path: '', redirectTo: '/home', pathMatch: 'full'},
                    {path: 'home', component: DashboardComponent},                  
                    {path: 'video-player/:lessonId', component: VideoPlayerComponent},
                    {path: 'responsive-container', component: FlexContainerComponent},
                    {path: 'quiz-component', component: QuizComponent},
                    {path: 'exam-training', component: ExamTrainingComponent},
                    {path: 'exam-simulation', component: ExamSimulationComponent},
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            //{path: 'login', component: AppLoginComponent},
            {path: 'login', component: LoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
