import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {QuizModel} from '@app/_models/quiz.model';
import {QuizDataModel} from '@app/_models/quiz-data.model';
import {map} from 'rxjs/operators';
import {QuizResultModel} from '@app/_models/quiz-result.model';
import {QuizDataClientModel} from '@app/_models/quiz-data-client.model';
import {QuizAnswerModel} from '@app/_models/quiz-answer.model';
import {NoteModel} from '@app/_models/note.model';
import {environment} from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private http: HttpClient) { }

  postAnswer(question: QuizModel, answer: boolean) {
    return answer === question.rightAnswer;
  }

  getQuizData(id: number) {
    //console.log('Getting quiz number ', id);

    return this.http.get<any[]>('./assets/json/quiz-data.json')
      .pipe(
        map((res) => {
          return res.filter(q => q.id === id)[0];
        }),
        map((data: QuizDataModel) => {
          const res = new QuizDataClientModel();
          res.quizData = data;
          return res;
        })
      );
  }

  getQuizListByLessonId(id: number) {
    return this.http.get<NoteModel[]>(`${environment.apiUrl}/LessonTopic/${id}/quiz-list`);
  }

  getQuizDataByQuizId(id: number) {
    return this.http.get<QuizDataModel>(`${environment.apiUrl}/LessonQuiz/${id}`)
      .pipe(
        map((data: QuizDataModel) => {
          const res = new QuizDataClientModel();
          res.quizData = data;
          return res;
        })
      );
  }

  postQuizResponse(answer: QuizAnswerModel) {
    //console.log('Checking answer for quiz number', answer.quizId, answer.answer);
    return this.http.post<QuizResultModel>(`${environment.apiUrl}/LessonQuiz/${answer.quizId}`, answer);
  }
}
