<div class="layout-sidebar" (click)="appMain.onMenuClick($event)">
    <a [routerLink]="['/']" class="logo">
        <img id="app-logo" class="logo-asgol" src="assets/layout/images/pages/asgol_black.png" alt="diamond-ng"
            style="margin: 1.5rem 0" />
    </a>

    <div class="layout-menu-container">
        <div class="layout-menu">
            <p-panelMenu [model]="items" [style]="{'max-height':'100%','overflow':'auto'}" #panelMenu></p-panelMenu>
        </div>
    </div>
</div>