<div class="p-grid">
    <div class="p-col-6 p-lg-12 p-md-6 p-sm-6">
        <p-card [style]="bgnote">
            <ng-template pTemplate="header">
                <div class="p-col-note" style="display: flex; justify-content: space-between; align-items: center">
                    <h4 style="margin: 0;"><i>I miei appunti:</i></h4>
                    <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-outlined"
                        (click)="addNote()"></button>
                </div>
            </ng-template>
            <div class="p-grid note-header">
                <div class="p-col-12" style="max-height: 30vh; min-height: 30vh; overflow-y: scroll;">
                    <div class="p-grid p-flex-column">

                        <div class="p-col target">
                            <div class="p-shadow-5 text-ellipses note-text" style="margin-bottom: 5px;"
                                *ngFor="let cuePoint of cuePointList let i = index" (click)="noteSelected(cuePoint)"
                                [ngClass]="(cuePoint.data.id !=null && cuePoint.data.id == selectedNote.data.id) && (cuePoint.data.text != null && cuePoint.data.text == selectedNote.data.text) ? 'note-menu-select' : 'note-menu'">
                                <span *ngIf="!cuePoint.data.global">
                                    <p class="pi pi-times delete-icon" (click)="removeNote(cuePoint)"></p>
                                    {{cuePoint.data.text}}
                                </span>
                                <span *ngIf="cuePoint.data.global"
                                    style="font-weight: bold;">{{cuePoint.data.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
    <br>
    <div class="p-col-6 p-lg-12 p-md-6 p-sm-6">
        <p-card *ngIf="showNoteDiv" [style]="bgnote2">
            <div class="note-edit">
                <div class="p-grid ">
                    <div class="p-col-12">
                        <div class="p-fluid p-formgrid p-grid p-jc-between">
                            <!--div class="p-field p-col-12 p-md-6">
                            <p-rating [(ngModel)]="selectedNote.data.rating"></p-rating>
                        </div-->
                            <!--div class="p-col-6 p-md-3">
                            <p>Highlight note when reached</p>
                            <p-inputSwitch [(ngModel)]="highlightNote"></p-inputSwitch>
                        </div-->
                            <!--div class="p-field p-col-12 p-md-6">
                        </div-->
                            <!--div class="p-field p-col-12">
                            <label for="title">Title</label>
                            <input id="title" type="text" [(ngModel)]="selectedNote.data.title" pInputText>
                        </div-->
                            <div class="p-field p-col-12">
                                <form [formGroup]="noteForm">
                                    <ng-container *ngIf="!selectedNote.data.global">
                                        <label for="note">Note:</label>
                                        <textarea id="note" formControlName="note" type="text" rows="7"
                                            [(ngModel)]="selectedNote.data.text" pInputTextarea
                                            *ngIf="!selectedNote.data.global">{{selectedNote.data.text}}</textarea>
                                    </ng-container>
                                    <ng-container *ngIf="selectedNote.data.global">
                                        <!--label for="note">Link utili:</label-->
                                        <div [innerHTML]="selectedNote.data.text" *ngIf="selectedNote.data.global">
                                        </div>
                                    </ng-container>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-grid p-jc-end" style="height: 24px;">
                    <!--div class="p-col p-lg-5 p-sm-12 p-md-12">
                </div-->
                    <div class="p-col-12 p-lg-8 p-sm-12"
                        *ngIf="selectedNote.data.start != null && !selectedNote.data.global">
                        <button style="float: right" pButton p-button-rounded type="button" icon="pi pi-play"
                            (click)="goToCutPoint()" *ngIf="noteEditing"></button>
                        <button style="float: right" pButton p-button-rounded type="button" icon="pi pi-plus-circle"
                            (click)="noteInserted()" [disabled]="!selectedNote.data.text" *ngIf="!noteEditing"></button>
                        <button style="float: right; margin-right: 0.5rem" pButton p-button-rounded type="button"
                            icon="pi pi-save" (click)="noteInserted()" [disabled]="!selectedNote.data.text"
                            *ngIf="noteEditing && noteForm.controls['note'].dirty"></button>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>