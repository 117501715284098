<div class="card" [ngStyle]="{'background-color': backgroundColor ? backgroundColor : 'white !important' }">
    <div class="p-grid">
        <ng-container *ngIf="enable">
            <div class="p-col-12 p-sm-12 p-md-10 p-lg-10">
                <h4 class="title">{{title}}</h4>
                <p-progressBar [value]="progressValue" [showValue]="false" class="progress-bar"></p-progressBar>
            </div>
            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2">
                <h2 class="percentage">{{progressValue | number: '1.0-0'}}%</h2>
            </div>
        </ng-container>

        <ng-container *ngIf="!enable">
            <div class="p-col-12">
                <h4 class="title">{{title}}</h4>
                <h5>{{explanation}}</h5>
            </div>
        </ng-container>
    </div>
</div>
