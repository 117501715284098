<button pButton pRipple type="button" icon="pi pi-plus" title ="Add Item" (click)="addItem()"></button>
<button pButton pRipple type="button" icon="pi pi-minus" title ="Remove Item" (click)="removeItem()"></button>
<div class="p-grid">
    <div class="p-co p-lg-4 p-sm-12" *ngFor="let item of items">
        <p-card header="{{item.header}}">
            <p>
                {{item.content}}
            </p>
        </p-card>
    </div>
</div>