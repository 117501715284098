<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
            <!--img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" /-->
            <img [src]="'assets/layout/images/pages/asgol_black.png'" alt="diamond-layout" class="imgVerticalLogin" />

            <div class="login-form" *ngIf="loginState==0">
                <h2>Login</h2>

                <form [formGroup]="loginForm">
                    <div class="input-group">
                        <input type="text" formControlName="email" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" pInputText placeholder="Email"
                            style="padding-right: 30px; width: 280px;">
                        <div class="icon-span"><i class="pi pi-user"></i></div>
                    </div>
                    <br>
                    <div class=" input-group">
                        <input [type]="hide ? 'text' : 'password'" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" pInputText
                            placeholder="Password" style="padding-right: 30px; width: 280px;">
                        <div class="icon-span"><i class="pi" [ngClass]="hide ? 'pi-eye-slash' : 'pi-eye'"
                                (click)="hide = !hide"></i></div>
                    </div>

                    <br>
                    <button pButton type="button" label="Accedi" icon="pi pi-user" (click)="onSubmit()"
                        style="margin: 0.8rem 0 1rem;"></button>
                </form>
                <p>Hai perso le credenziali? Clicca qui per <br>
                    <a href="https://www.autoscuolaonline.net/area-riservata/recupera-password"
                        target="_blank">recuperare
                        la password</a>
                </p>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </div>

            <div class="login-form" *ngIf="loginState==1">
                <h2>Recupero credenziali</h2>
                <p>Inserisci l'email che hai scelto in fase di acquisto del corso</p>
                <form [formGroup]="lostPasswordForm">
                    <input type="text" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f2.email.errors }" pInputText placeholder="Email"> <br>
                    <button pButton type="button" label="Annulla" icon="pi pi-undo" class="p-button-warning"
                        (click)="onLostPasswordExit()"></button>
                    <button pButton type="button" label="Invia" icon="pi pi-key"
                        (click)="onLostPasswordEmailSubmit()"></button>
                </form>
            </div>

            <div class="login-form" *ngIf="loginState==2">
                <h2>Recupero credenziali</h2>
                <p>Inserisci il codice di verifica ricevuto sulla tua email</p>
                <p class="lostpassword-error" *ngIf="validateCodeError">CODICE NON VALIDO</p>
                <form [formGroup]="validatePasswordForm">
                    <input type="text" formControlName="token" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f3.token.errors }" pInputText
                        placeholder="Codice di verifica"> <br>
                    <button pButton type="button" label="Annulla" icon="pi pi-undo" class="p-button-warning"
                        (click)="onLostPasswordExit()"></button>
                    <button pButton type="button" label="Invia" icon="pi pi-key"
                        (click)="onLostPasswordValidateSubmit()"></button>

                </form>
            </div>

            <div class="login-form" *ngIf="loginState==3">
                <h2>Recupero credenziali</h2>
                <p>Inserisci la nuova password</p>
                <form [formGroup]="resetPasswordForm">
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f4.password.errors }" pInputText placeholder="Password">
                    <br>
                    <input type="password" formControlName="confirmPassword" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f4.confirmPassword.errors }" pInputText
                        placeholder="Ripeti la password">
                    <br>
                    <button pButton type="button" label="Salva" icon="pi pi-save"
                        (click)="onLostPasswordChangeSubmit()"></button>
                </form>
            </div>

            <p>Problemi? <a href="https://api.whatsapp.com/send?phone=393516582078&text=Salve" target="_blank">Clicca
                    qui</a> e ti aiuteremo</p>
        </div>

        <div class="login-image">
            <img src="assets/layout/images/pages/bg-login-1.jpg" [style.opacity]="bgcounter == 1 ? 1 : 0" alt="">
            <img src="assets/layout/images/pages/bg-login-2.jpg" [style.opacity]="bgcounter == 2 ? 1 : 0" alt="">
            <img src="assets/layout/images/pages/bg-login-3.jpg" [style.opacity]="bgcounter == 3 ? 1 : 0" alt="">
            <img src="assets/layout/images/pages/bg-login-4.jpg" [style.opacity]="bgcounter == 4 ? 1 : 0" alt="">
        </div>
    </div>
</div>