import { Injectable } from '@angular/core';
import { QuizDataModel } from '@app/_models/quiz-data.model';
import { QuizResultModel } from '@app/_models/quiz-result.model';
import { map } from 'rxjs/operators';
import { QuizDataClientModel, QuizListModel } from '@app/_models/quiz-data-client.model';
import { QuizAnswerModel } from '@app/_models/quiz-answer.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamSimulationService {

  constructor(
    private http: HttpClient
  ) { }

  getQuizList() {
    return this.http.get<QuizListModel[]>(`${environment.apiUrl}/ExamQuiz/quiz-list/exam`);
  }

  getQuizDataByQuizId(id: number) {
    return this.http.get<QuizDataModel>(`${environment.apiUrl}/ExamQuiz/${id}`)
      .pipe(
        map((data: QuizDataModel) => {
          const res = new QuizDataClientModel();
          res.quizData = data;
          return res;
        })
      );
  }

  postQuizResponse(answer: QuizAnswerModel) {
    //console.log('Checking answer for quiz number', answer.quizId, answer.answer);
    return this.http.post<QuizResultModel>(`${environment.apiUrl}/ExamQuiz/simulation/${answer.quizId}`, answer);
  }
}
