<p-messages severity="warn" *ngIf="trialMode">
    <ng-template pTemplate>
        <div class="p-ml-2">Benvenuto/a sulla nostra piattaforma. Hai 24 ore per provarla. Per continuare ad utilizzarla torna sul sito <a href="https://www.autoscuolaonline.net" target="_blank">www.autoscuolaonline.net</a> e completa l'acquisto. Buon corso!</div>
    </ng-template>
</p-messages>
<!--button pButton pRipple type="button"
        label="decreaseLessonProgress"
        (click)="decreaseLessonProgress()"
        style="width: 99%; margin: 0.5%; height: 100%">
</button>
<div class="p-grid">
    <div class="p-col-1">
        <button pButton pRipple type="button"
                label="Exam training 0"
                (click)="examTrainingProgress = 0"
                style="width: 99%; margin: 0.5%; height: 100%">
        </button>
    </div>
    <div class="p-col-1">
        <button pButton pRipple type="button"
                label="Exam simulation 0"
                (click)="examSimulationProgress = 0"
                style="width: 99%; margin: 0.5%; height: 100%">
        </button>
    </div>
    <div class="p-col-1">
        <button pButton pRipple type="button"
                label="Answers right empty"
                (click)="itemToShowRight = []"
                style="width: 99%; margin: 0.5%; height: 100%">
        </button>
    </div>
</div-->

<div class="p-grid" [@fadeAnimation]>
    <div class="p-col-12">
        <app-progress [backgroundColor]="" [title]="'Avanzamento del corso'" [progressValue]='progressValueLesson'
            [enable]="lessonStatistics.total > 0 ? true : false"
            [explanation]="'Non hai ancora completato nessuna lezione'"></app-progress>
    </div>

    <!--div class="p-col-6 p-lg-9 p-md-9 p-sm-6">
        <app-progress
                [backgroundColor]=""
                [title]="'Allenamento esame: percentuale successo dei quiz'"
                [progressValue]="progressValueTraining"
                [explanation]="'Le statistiche saranno disponibili solo dopo aver effetuato i quiz'"></app-progress>
    </div>
    <div class="p-col-6 p-lg-3 p-md-3 p-sm-6">
        <app-question-totalizer
                [backgroundColor]=""
                [titleClass]="'pi pi-shopping-cart'"
                [title]="'Quiz'"
                [firstInfo]="examTrainingStatistics.total ? examTrainingStatistics.total : 0"
                [firstInfoDescription]="'Totali'"
                [secondInfo]="examTrainingStatistics.pass ? examTrainingStatistics.pass : 0"
                [secondInfoDescription]="'Corretti'"></app-question-totalizer>
    </div-->

    <!--div class="p-col-6 p-lg-9 p-md-9 p-sm-6">
        <app-progress [backgroundColor]="" [title]="'Simulazioni esame positive'"
            [progressValue]="progressValueSimulation" [enable]="this.examSimulationStatistics.total > 0 ? true : false"
            [explanation]="'Le statistiche saranno disponibili solo dopo aver effetuato la simulazione d\'esame'">
        </app-progress>
    </div>
    <div class="p-col-6 p-lg-3 p-md-3 p-sm-6">
        <app-question-totalizer [backgroundColor]="" [titleClass]="'pi pi-plus'" [title]="'Simulazioni d\'esame'"
            [firstInfo]="examSimulationStatistics.total ? examSimulationStatistics.total : 0"
            [firstInfoDescription]="'Totali'"
            [secondInfo]="examSimulationStatistics.pass ? examSimulationStatistics.pass : 0"
            [secondInfoDescription]="'Superate'"></app-question-totalizer>
    </div-->
    <div class="p-col-12">
        <app-exam-totalizer [title]="'Statistiche simulazioni esame'" [data]="examSimulationStatistics"></app-exam-totalizer>
    </div>

    <div class="p-col-12 p-lg-6 p-md-6 p-sm-12">
        <app-answers-board [title]="'Statistica allenamento'" [subtitle]="'I grafici indicano la percentuale di successo dei quiz svolti nel determinato argomento'"
            [backgroundColor]="" [data]="examTrainingDetailStatisticsData"
            [explanation]="'Le statistiche saranno disponibili solo dopo aver effetuato i quiz nella sezione allenamento'"
            [scrollHeight]="'20vh'"></app-answers-board>
    </div>
    <div class="p-col-12 p-lg-6 p-md-6 p-sm-12">
        
        <app-answers-board [title]="'Statistica simulazione esame'" [subtitle]="'I grafici indicano la percentuale di successo dei quiz svolti nel determinato argomento nelle simulazioni d\'esame'"
            [backgroundColor]="" [data]="examSimulationDetailStatisticsData"
            [explanation]="'Le statistiche saranno disponibili solo dopo aver effetuato almeno una simulazione d\'esame'"
            [scrollHeight]="'20vh'"></app-answers-board>
    </div>

    <div class="p-col-12">
        <app-monthly-quiz
                [title]="'Statistica quiz'"
                [data]="quizDetailStatisticsData"
                [graphOptions]="graphOptions"
                [explanation]="'Le statistiche saranno disponibili solo dopo aver effetuato almeno un allenamento lezione o una simulazione d\'esame '"></app-monthly-quiz>
    </div>

</div>
