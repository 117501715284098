import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { QuizDataClientModel, QuizListModel } from '@app/_models/quiz-data-client.model';
import { QuizAnswerModel } from '@app/_models/quiz-answer.model';
import { QuizResultModel } from '@app/_models/quiz-result.model';
import { fadeAnimationIn, inOutAnimation, rotateCardAnimation } from '@app/_animations/animations';
import { ExamSimulationService } from '@app/_services/exam-simulation.service';
import { GenericService } from '@app/_services/generic.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-exam-simulation',
  templateUrl: './exam-simulation.component.html',
  styleUrls: ['./exam-simulation.component.scss'],
  animations: [
    fadeAnimationIn,
    inOutAnimation,
    rotateCardAnimation
  ]
})
export class ExamSimulationComponent implements OnInit {

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChildren('cd') private countdownElements: QueryList<ElementRef>;
  
  apiUrl = environment.apiUrl;

  quizNumber= 0;
  questionsAnswered = 0;
  currentQuestion: QuizDataClientModel = new QuizDataClientModel();

  topics = [];
  quizIdList: QuizListModel[] = [];
  totalQuestions = 0;
  actualQuestionIndex = 0;
  quizDataList: QuizDataClientModel[] = [];
  wrongQuizList: QuizDataClientModel[] = [];
  rightQuizList: QuizDataClientModel[] = [];

  correctAnswers = 0;
  wrongAnswers = 0;

  examResultImgOk = '';
  examResultImgKo = '';
  examResultImgKo2 = '';

  showExplanation = false;
  showLastQuestionExplanation = false;
  isQuizFinished = false;
  quizFinished = false;
  showMenu = true;
  disableQuizAnswer = true;
  disableStartButton = false;

  examTime = 1200;
  timerConfig = {
    leftTime: this.examTime,
    format: 'mm:ss'
  };

  currentTopic = 0;
  sessionToken = '';

  constructor(
    private examSimulationService: ExamSimulationService,
    private genericService: GenericService
  ) { }

  ngOnInit(): void { }

  beginExam() {
    this.disableStartButton = true;
    this.sessionToken = this.genericService.generateSessionToken();
    this.quizNumber = 0;
    this.examSimulationService.getQuizList().subscribe(quizIdList => {
      this.showMenu = false;
      //console.log('Lesson topic quiz list', quizIdList);
      this.quizIdList = quizIdList;
      this.totalQuestions = quizIdList.length;
      this.getQuizData();
    });
  }

  getQuizData() {
    this.examSimulationService.getQuizDataByQuizId(this.quizIdList[this.actualQuestionIndex].quizId).subscribe(r => {
      this.currentQuestion = r;
      this.quizDataList.push(r);
      this.showMenu = false;
      this.disableQuizAnswer = false;
      this.quizNumber++;
    });
  }

  answer(result: boolean) {
    this.currentQuestion.answer = result;

    // Check to prevent multiple answer of the same question
    if (!this.currentQuestion.answered) {
      this.disableQuizAnswer = true;
      const quizAnswer = new QuizAnswerModel();
      quizAnswer.answer = result;
      quizAnswer.quizId = this.currentQuestion.quizData.id;
      quizAnswer.sessionToken = this.sessionToken;
      this.examSimulationService.postQuizResponse(quizAnswer).subscribe(r => {
        if (this.questionsAnswered + 1 <= this.totalQuestions) {
          this.questionsAnswered++;
        } else {
          this.quizFinished = true;
        }
        this.currentQuestion.quizResult = r;
        //this.currentQuestion.quizData.questionText = '';
        //this.currentQuestion.quizData.image = null;
        //console.log('response for the answer', r);
        // Here i've to check the response
        this.handleResponseCheck(r);
        if (this.calculateQuizCompletion() === 100 || this.quizFinished) {
          this.isQuizFinished = true;
          this.createQuizStatistics();
        }
      });
    }
  }

  handleTimerEvent($event) {
    //console.log($event);
    this.timerConfig.leftTime = $event.left / 1000;
    if (this.timerConfig.leftTime === 0) {
      this.handleAnswersLeft();
    }
  }

  // Set all the question left as wrong
  handleAnswersLeft() {
    // If less answer was done
    if (this.questionsAnswered !== this.quizIdList.length) {
      this.wrongAnswers = this.wrongAnswers + (this.quizIdList.length - this.questionsAnswered);
      this.isQuizFinished = true;
      this.showExplanation = false;
      this.createQuizStatistics();
    }
  }

  handleResponseCheck(answer: QuizResultModel) {
    this.currentQuestion.answered = true;
    // If the answer is wrong, show video and pause countdown
    if (!answer.result) {
      //console.log('Wrong answer.');
      this.wrongAnswers ++;
      this.currentQuestion.isCorrectAnswer = false;

    } else {
      //console.log('Right answer.');
      this.correctAnswers ++;
      this.currentQuestion.isCorrectAnswer = true;
    }
    this.goToNextQuestion();
  }

  goToNextQuestion() {
    if (this.actualQuestionIndex !== this.totalQuestions - 1) {
      this.nextQuestion();
    }
  }

  backToQuiz() {
    this.showExplanation = false;
    this.showLastQuestionExplanation = false;
    this.goToNextQuestion();
  }

  nextQuestion() {
    this.actualQuestionIndex++;

    // Getting the next quizData, if not already present in the quizDataList
    if (!this.quizDataList[this.actualQuestionIndex]) {
      //console.log('Question not loaded. Asking be..');
      this.getQuizData();
    } else {
      //console.log('Question data already loaded.');
      //console.log('Actual question ' + this.currentQuestion.quizData.id);
      this.currentQuestion = this.quizDataList[this.actualQuestionIndex];
      this.disableQuizAnswer = false;
    }
  }

  calculateQuizCompletion(): number {
    const quizCompletion = Math.round((this.questionsAnswered / this.totalQuestions) * 100);
    if (quizCompletion === 100) {
      try {
        this.countdown.pause();
      } catch (e) {}
    }
    return quizCompletion;
  }

  createQuizStatistics() {
    // Extract the question answered in the wrong way
    this.wrongQuizList = this.quizDataList.filter(qd => qd.isCorrectAnswer === false);
    this.rightQuizList = this.quizDataList.filter(qd => qd.isCorrectAnswer === true);
  }

  calculateCorrectPercentage() {
    return this.correctAnswers / this.totalQuestions;
  }

  initializeComponent() {
    this.initializeTimer();
    this.disableStartButton = false;
    this.showMenu = true;
    this.actualQuestionIndex = 0;
    this.quizDataList = [];

    this.questionsAnswered = 0;
    this.correctAnswers = 0;
    this.wrongAnswers = 0;

    this.showExplanation = false;
    this.showLastQuestionExplanation = false;
    this.isQuizFinished = false;
    this.quizFinished = false;
    this.currentQuestion = new QuizDataClientModel();
  }

  showExplanationFinal(wrongQuiz) {
    this.showExplanation = true;
    this.currentQuestion = wrongQuiz;
    console.log(wrongQuiz);
  }

  initializeTimer() {
    this.timerConfig = {
      leftTime: this.examTime,
      format: 'mm:ss'
    };
  }

  translateAnswer(answer: boolean): string {
    if (answer) {
      return 'Vero';
    } else {
      return 'Falso';
    }
  }

  getQuizIndex(quizId: number): number {
    return this.quizIdList.findIndex(qid => qid.quizId === quizId);
  }

  getRandomArbitrary(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }
  
}
