<div class="layout-topbar">
    <div class="hide-on-big" style="width: 100%; margin-top: 10px">
        <div class="p-grid">
            <div class="p-col box">
                <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
                    <i class="pi pi-chevron-left"></i>
                </a>
            </div>
            <div class="p-col box">
                <!--ul class="topbar-menu" style="justify-content: center">
                    <li class="profile-item">
                        <a href="https://api.whatsapp.com/send?phone=393516582078&text=Salve" target="_blank">
                            <i class="pi pi-shield"></i> <span class="profile-name">Contatta il Tutor</span>
                        </a>
                    </li>
                </ul-->
            </div>
            <div class="p-col box">
                <!--li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                    <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                        <img src="assets/layout/images/user.jpg" alt="diamond-layout" class="profile-image"/>
                        <span class="profile-name">{{user.firstName}} {{user.lastName}}</span>
                    </a>
                    <ul class="profile-menu fade-in-up" style="margin-left: 5rem;">
                        <li>
                            <a href="#" (click)="logout()">
                                <i class="pi pi-power-off"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li-->
                <ul class="topbar-menu" style="justify-content: end">
                    <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                        <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                            <img src="assets/layout/images/user.png" alt="diamond-layout" class="profile-image"
                                style="margin-top: 3px;" />
                            <span class="profile-name">{{user.firstName}} {{user.lastName}}</span>
                        </a>
                        <ul class="profile-menu fade-in-up">
                            <li>
                                <a href="#" (click)="logout()">
                                    <i class="pi pi-power-off"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>
    </div>
    <div class="topbar-left hide-on-small">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi pi-chevron-left"></i>
        </a>
        <!--span class="topbar-separator"></span>
        <ng-template-- ngFor let-item let-last="last" [ngForOf]="items">
            <span class="viewname" style="text-transform: uppercase">{{item.label}}</span>
        </ng-template-->
        <!--img id="logo-mobile" class="mobile-logo" [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" alt="diamond-layout" /-->

        <ul>

        </ul>
    </div>

    <div class="topbar-right hide-on-small">
        <ul class="topbar-menu">
            <!--li class="profile-item">
                <a href="https://api.whatsapp.com/send?phone=393516582078&text=Salve" target="_blank">
                    <i class="pi pi-shield"></i> Contatta il Tutor
                </a>
            </li-->
            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <img src="assets/layout/images/user.png" alt="diamond-layout" class="profile-image" />
                    <span class="profile-name">{{user.firstName}} {{user.lastName}}</span>
                </a>
                <ul class="profile-menu fade-in-up">
                    <li>
                        <a href="#" (click)="logout()">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!--li class="right-sidebar-item">
                <a href="#" tabindex="0" (click)="appMain.onRightMenuClick($event)">
                    <i class="pi pi-align-right"></i>
                </a>
            </li-->
        </ul>
    </div>
</div>