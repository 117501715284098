<div class="card widget-overview-box widget-overview-box-1" [ngStyle]="{'background-color': backgroundColor ? backgroundColor : 'white !important' }">
    <div class="p-col-12">
        <span class="overview-icon">
            <i [ngClass]="'pi pi-plus'"></i>
        </span>
        <span class="overview-title">{{title}}</span>
    </div>

    <div class="p-grid">
        <div class="p-col-4 overview-detail">
            <div class="overview-number">{{data.total ? data.total : 0}}</div>
            <div class="overview-subtext">
                <i class="pi pi-list"></i>
                Totale
            </div>
        </div>
        <div class="p-col-4 overview-detail">
            <div class="overview-number">{{data.pass ? data.pass : 0}}</div>
            <div class="overview-subtext">
                <i class="pi pi-thumbs-up"></i>
                Superato
            </div>
        </div>
        <div class="p-col-4 overview-detail">
            <div class="overview-number">{{data.total - data.pass}}</div>
            <div class="overview-subtext">
                <i class="pi pi-thumbs-down"></i>
                Respinto
            </div>
        </div>
    </div>
</div>
