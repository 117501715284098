import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  EventEmitter,
  Input,
  SimpleChanges
} from '@angular/core';
import {QuizService} from '@app/_services/quiz.service';
import {CountdownComponent} from 'ngx-countdown';
import {fadeAnimation, inOutAnimation} from '@app/_animations/animations';
import {QuizResultModel} from '@app/_models/quiz-result.model';
import {QuizDataClientModel} from '@app/_models/quiz-data-client.model';
import {QuizAnswerModel} from '@app/_models/quiz-answer.model';
import {LessonTopicModel} from '@app/_models/lesson-topic.model';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  animations: [
    fadeAnimation,
    inOutAnimation
  ]
})
export class QuizComponent implements OnInit, AfterViewInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @ViewChildren('cd') private countdownElements: QueryList<ElementRef>;

  @Output() quizFinished = new EventEmitter<boolean>();
  @Output() nextLessonTopic = new EventEmitter<boolean>();
  @Output() restartLessonTopic = new EventEmitter<boolean>();
  @Input() nextLessonTopicId: string;
  @Input() lessonTopic: LessonTopicModel;

  apiUrl = environment.apiUrl;

  isQuizFinished = false;
  quizNumber= 0;

  quizIdList = [];
  showExplanation = false;
  showLastQuestionExplanation = false;

  actualQuestionIndex = 0;
  questionsAnswered = 0;
  correctAnswers = 0;
  wrongAnswers = 0;
  quizCompleted = false;
  totalQuestions = this.quizIdList.length;
  disableQuizAnswer = true;

  quizDataList: QuizDataClientModel[] = [];
  currentQuestion: QuizDataClientModel = new QuizDataClientModel();
  currentAnswer: QuizResultModel = new QuizResultModel();

  timerConfig = {
    leftTime: 0,
    format: ''
  };

  nextLessonTimerConfig = {
    leftTime: 2,
    format: 's'
  };

  quizStatistics = {};

  quizResultImg: string;

  pieOptions = {
    legend: {
      labels: {
        fontColor: '#eee8e8',
      }
    }
  };

  resultBgColor = null;

  resultTitle = '';
  resultText = '';

  constructor(
      private quizService: QuizService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lessonTopic?.currentValue.id) {
      this.quizService.getQuizListByLessonId(changes.lessonTopic.currentValue.id).subscribe(quizIdList => {
        //console.log('Lesson topic quiz list', quizIdList);
        this.quizIdList = quizIdList;
        this.totalQuestions = quizIdList.length;

        // Set timer lenght based on question number
        this.timerConfig = {
          leftTime: this.totalQuestions * 10,
          format: 'mm:ss'
        };

        this.getQuizData();
      });
    }
  }

  getQuizData() {
    this.quizService.getQuizDataByQuizId(this.quizIdList[this.actualQuestionIndex].quizId).subscribe(r => {
      this.disableQuizAnswer = false;
      this.currentQuestion = r;
      this.quizDataList.push(r);
      this.quizNumber++;
    });
  }

  ngAfterViewInit() {
    this.countdownElements.changes.subscribe(() => {
      //console.log(
      //    'Countdown in the DOM!',
      //    this.countdownElements.length
      //);
    });
  }

  nextQuestion() {
    this.actualQuestionIndex++;

    // Getting the next quizData, if not already present in the quizDataList
    if (!this.quizDataList[this.actualQuestionIndex]) {
      //console.log('Question not loaded. Asking be..');
     this.getQuizData();
    } else {
      //console.log('Question data already loaded.');
      //console.log('Actual question ' + this.currentQuestion.quizData.id);
      this.currentQuestion = this.quizDataList[this.actualQuestionIndex];
      this.disableQuizAnswer = false;
    }
  }

  answer(result: boolean) {

    this.questionsAnswered++;
    this.currentQuestion.answer = result;
    const questionToUpdate = this.currentQuestion;

    // Check to prevent multiple answer of the same question
    if (!this.currentQuestion.answered) {
      this.disableQuizAnswer = true;
      const quizAnswer = new QuizAnswerModel();
      quizAnswer.answer = result;
      quizAnswer.quizId = this.currentQuestion.quizData.id;
      this.quizService.postQuizResponse(quizAnswer).subscribe(r => {
        this.currentAnswer = r;
        //this.currentQuestion.quizData.questionText = '';
        this.currentQuestion.quizData.image = null;
        // NEEDS TO BE REFACTORED
        this.currentQuestion.quizResult = r;
        //console.log('response for the answer', r);
        // Here i've to check the response
        this.handleResponseCheck(questionToUpdate, r);
        if (this.calculateQuizCompletion() === 100) {
          this.quizCompleted = true;
          this.isQuizFinished = true;
        }
      });
    }
  }

  isQuestionAnswered() {
    return this.currentQuestion.answered;
  }

  handleTimerEvent($event) {
    //console.log($event);
    this.timerConfig.leftTime = $event.left / 1000;
    if (this.timerConfig.leftTime === 0) {
      this.handleAnswersLeft();
    }
  }

  handleResponseCheck(question: QuizDataClientModel, answer: QuizResultModel) {
    question.answered = true;
    // If the answer is wrong, show video and pause countdown
    if (!answer.result) {
      //console.log('Wrong answer.');
      this.wrongAnswers ++;
      try {
        this.countdown.pause();
      } catch (e) {}
      this.showExplanation = true;
      question.isCorrectAnswer = false;

      // If I answer in the wrong way to the last question
      if (this.actualQuestionIndex === this.totalQuestions) {
        this.showLastQuestionExplanation = true;
      }
    } else {
      //console.log('Right answer.');
      this.correctAnswers ++;
      question.isCorrectAnswer = true;
      this.goToNextQuestion();
    }

    this.createQuizStatistics();
  }

  // Set all the question left as wrong
  handleAnswersLeft() {
    // If less answer was done
    if (this.questionsAnswered !== this.quizIdList.length) {
      this.wrongAnswers = this.wrongAnswers + (this.quizIdList.length - this.questionsAnswered);
      this.isQuizFinished = true;
      this.showExplanation = false;
      this.createQuizStatistics();
    }
  }

  backToQuiz() {
    this.showExplanation = false;
    this.showLastQuestionExplanation = false;
    this.currentAnswer.explanationText = '';
    this.goToNextQuestion();
  }

  backToQuiz2() {
    this.createQuizStatistics();
  }

  goToNextQuestion() {
    if (this.actualQuestionIndex !== this.totalQuestions - 1) {
      this.nextQuestion();
    }
  }

  calculateQuizCompletion(): number {
    const quizCompletion = Math.round((this.questionsAnswered / this.totalQuestions) * 100);
    if (quizCompletion === 100) {
      try {
        this.countdown.pause();
      } catch (e) {}
    }
    return quizCompletion;
  }

  getQuizStyle() {
    if (this.currentQuestion) {
      if (this.currentQuestion.isCorrectAnswer !== undefined &&
        this.currentQuestion.isCorrectAnswer || (this.isQuizFinished && !this.showExplanation)) {
        return 'quiz-card-right';
      } else if (this.currentQuestion.isCorrectAnswer != null &&
        !this.currentQuestion.isCorrectAnswer) {
        return 'quiz-card-wrong';
      } else {
        return 'quiz-card';
      }
    }
  }

  createQuizStatistics() {
    this.quizStatistics = {
      labels: ['Risposte corrette', 'Risposte errate'],
      datasets: [{
        data: [this.correctAnswers, this.wrongAnswers],
        backgroundColor: ['#66BB6A', '#FF0000']
      }]
    };

    const currentCorrectPercentage = this.calculateCorrectPercentage();

    if (currentCorrectPercentage <= 0.5) {
      this.resultBgColor = '#e73b3b';
      this.resultTitle = 'Non ci siamo...';
      this.resultText = 'Non hai raggiunto la sufficienza. Ti consigliamo di riguardare la lezione.';
    } else if (currentCorrectPercentage < 0.8) {
      this.resultBgColor = '#f47f37';
      this.resultTitle = 'Ci siamo quasi';
      this.resultText = 'Ancora uno sforzo! Ripassando la lezione otterrai sicuramente un risultato migliore.';
    } else if (currentCorrectPercentage < 1) {
      this.resultBgColor = '#66BB6A';
      this.resultTitle = 'Molto bene!';
      this.resultText = 'La tua preparazione su questa lezione è quasi perfetta. Puoi proseguire con la lezione successiva... a meno che tu non voglia ottenere un risultato perfetto.';
    }else {
      this.resultBgColor = '#41df9c';
      this.resultTitle = 'Perfetto!';
      this.resultText = 'Complimenti, questa lezione non ha più segreti per te. Prosegui pure con la prossima lezione.';
    }
  }

  calculateCorrectPercentage() {
    return this.correctAnswers / this.totalQuestions;
  }

  goToNextLessonTopic() {
    this.nextLessonTopic.emit(true);
  }
}
