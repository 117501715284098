import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Pipe({
  name: 'authImage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}
  
  async transform(src: string): Promise<any> {
    const user = this.authenticationService.userValue;
    const headers = new HttpHeaders({'Authorization': `Bearer ${user.jwtToken}`});
    const imageBlob = await this.http.get(src, {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }

}
