import { Component, Input, OnInit } from '@angular/core';
import { ExamStatisticModel } from '@app/_models/exam-statistic.model';

@Component({
  selector: 'app-exam-totalizer',
  templateUrl: './exam-totalizer.component.html',
  styleUrls: ['./exam-totalizer.component.scss']
})
export class ExamTotalizerComponent implements OnInit {

  @Input() title = '';
  @Input() backgroundColor = '';
  @Input() data: ExamStatisticModel = new ExamStatisticModel();

  constructor() { }

  ngOnInit(): void {
  }

}
