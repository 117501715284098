<div class="p-grid quiz-container">
    <div class="p-col-12 p-lg-12 p-md-12 p-sm-12" [ngClass]="getQuizStyle()">
        <div class="p-grid question-container" *ngIf="!showExplanation && !isQuizFinished">
            <div class="p-col-6 quiz-timer">
                <p class="quiz-clock-info-big quiz-status-info">
                    <i class="pi pi-clock" style="font-size: 2rem"></i>
                    <countdown #cd [config]="timerConfig" (event)="handleTimerEvent($event)"></countdown>
                </p>
                <p class="quiz-clock-info-small quiz-status-info">
                    <i class="pi pi-clock" style="font-size: 6.5vw"></i>
                    <countdown #cd [config]="timerConfig" (event)="handleTimerEvent($event)"></countdown>
                </p>
            </div>
            <div class="p-col-6 quiz-timer">
                <p class="quiz-status-info">Quiz {{this.quizNumber}}/{{this.totalQuestions}}</p>
            </div>
            <div class="p-col-12 divider-top">
                <p-divider></p-divider>
            </div>
            <ng-container *ngIf="currentQuestion.quizData.image">
                <div class="p-col-12 p-lg-3 p-md-3 box quiz-image-container">
                    <div class="box quiz-image">
                        <img *ngIf="currentQuestion && currentQuestion.quizData.image"
                            [src]="apiUrl +'/LessonQuiz/media/' + currentQuestion.quizData.id | authImage | async">
                    </div>
                </div>
            </ng-container>
            <div [ngClass]="{
                'p-col box': currentQuestion.quizData.image,
                'p-col-12 text-centered': !currentQuestion.quizData.image}">
                <div class="box quiz-text" *ngIf="currentQuestion.quizData.image">
                    <p *ngIf="currentQuestion">{{currentQuestion.quizData.questionText}}</p>
                </div>
                <div class="quiz-text-original-position" *ngIf="!currentQuestion.quizData.image">
                    <p *ngIf="currentQuestion">{{currentQuestion.quizData.questionText}}</p>
                </div>
            </div>
            <div class="p-col-12 divider-bottom">
                <p-divider></p-divider>
            </div>
            <div class="p-col-12 box">
                <div class="p-grid">
                    <div class="p-col-6 p-md-4 p-sm-12 p-md-offset-2 p-lg-offset-2 box padding">
                        <button pButton type="button" class="quiz-button-response p-button-success" label="Vero"
                            style="width: 100%;" icon="pi pi-check" (click)="answer(true)"
                            [disabled]="isQuestionAnswered() || disableQuizAnswer"></button>
                    </div>
                    <div class="p-col-6 p-md-4 p-sm-12 box padding">
                        <button pButton type="button" class="quiz-button-response p-button-danger" label="Falso"
                            style="width: 100%;" icon="pi pi-times" (click)="answer(false)"
                            [disabled]="isQuestionAnswered() || disableQuizAnswer"></button>
                    </div>
                </div>
            </div>
            <div class="p-col-12 quiz-completion">
                <p-progressBar [value]="calculateQuizCompletion()"></p-progressBar>
            </div>
        </div>

        <!-- EXPLANATION SIDE OF THE CARD -->

        <div class="p-grid explanation-container box" *ngIf="showExplanation">
            <div class="p-col-12 p-lg-4 p-md-4 p-sm-12 explanation-image box"
                *ngIf="this.currentQuestion.quizData.image">
                <img *ngIf="currentAnswer"
                    [src]="apiUrl + '/LessonQuiz/media/' + currentQuestion.quizData.id | authImage | async">
            </div>
            <div [ngClass]="{
            'p-col-12 p-lg-8 p-md-8 p-sm-12 box': currentQuestion.quizData.image,
            'p-col-12': !currentQuestion.quizData.image}">
                <div style="text-align: center;">
                    <h2 style="color: #f06e6e;">Risposta errata!</h2>
                </div>
                <div class="explanation-text">
                    <p>
                        <b>Quesito:</b> {{this.currentQuestion.quizData.questionText}}
                    </p>
                    <div style="text-align: start;width:fit-content; margin: auto; margin-bottom: 1rem">
                        <p>
                            <b>Hai risposto:</b> {{this.currentQuestion.answer ? 'VERO' :
                            'FALSO'}}
                            <br>
                            <b>Risposta corretta:</b> {{this.currentQuestion.answer ? 'FALSO' : 'VERO'}}
                        </p>
                    </div>
                    <p
                        *ngIf="this.currentQuestion.quizData.questionText != this.currentQuestion.quizResult.explanationText">
                        <b>Spiegazione: </b>
                        {{this.currentQuestion.quizResult.explanationText}}
                    </p>
                </div>
            </div>
            <!-- <img class="emoji-wrong-quiz" src="assets/layout/images/pages/quiz-wrong.png"> -->
            <div [ngClass]="{
                'p-col-12 p-lg-4 p-md-4 p-sm-12 p-md-offset-4 p-lg-offset-4': true}">
                <button pButton pRipple type="button" label="Continua" (click)="backToQuiz()"
                    style="width: 99%; margin: 0.5%;">
                </button>

            </div>
        </div>

        <!-- STATISTICS -->
        <div class="p-grid" *ngIf="isQuizFinished && !showExplanation">
            <div class="p-col-12 p-lg-6 p-md-12 p-sm-12 p-lg-offset-3" style="margin-top: 2%">
                <h2>
                    <p-chart type="pie" id="revenue-chart" height="30vh" [data]="quizStatistics"
                        [style]="{'margin-top': '10%'}" [options]="pieOptions"></p-chart>
                </h2>
                <div [style.backgroundColor]="resultBgColor"
                    style="border-radius: 10px;color: #ffffff; padding: 2.5rem">
                    <h4 style="margin: 0">{{this.resultTitle}}</h4>
                    <p style="font-size: 1.1rem;">{{this.resultText}}</p>
                </div>
                <div [ngClass]="{'p-col-12 p-lg-4 p-md-4 p-sm-12 p-md-offset-4 p-lg-offset-4': true}"
                    *ngIf="this.nextLessonTopicId !== ''" style="width: 100%; margin: 0">
                    <app-countdown-button *ngIf="nextLessonTopicId" [countdownEnabled]="false"
                        [label]="'Prossima lezione'" [timerFormat]="'s'" [duration]="10"
                        (timerOver)="goToNextLessonTopic()"
                        (buttonClicked)="goToNextLessonTopic()"></app-countdown-button>
                    <button pButton pRipple type="button" label="Ricomincia lezione"
                        (click)="this.restartLessonTopic.emit(true)" style="width: 99%; margin: 0.5%;">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>