export class NoteModel {
    id: number;
    lessonTopicId?: number;
    // user?: User;
    title?: string;
    text?: string;
    start?: number;
    rating: 0;
    global: boolean;
}

export class NoteRequestModel {
    lessonTopicId?: number;
    title?: string;
    text?: string;
    start?: number;
    rating: 0;
    accountId: 0;
}
